import { action, computed, makeObservable, observable } from "mobx";

class AuthStore {

    _auth = false;

    constructor(value) {
        makeObservable(this, {
            _auth: observable,
            auth: computed,
            setAuth: action,
        })
        this.value = value
    }

    get auth() { return this._auth; }
    setAuth(auth) { this._auth = auth; }

}

const authStore = new AuthStore()

export default authStore