import React from 'react';
import { Container } from 'react-bootstrap'
import HeaderNavBar from '../component/NarBar/HeaderNavBar';
import { Helmet } from 'react-helmet-async';

function MasterPage(props) {
    return (
        <>
            <Helmet>
                <link rel="canonical" href={window.location.origin} />
            </Helmet>
            <HeaderNavBar />
            <Container>
                {props.children}
            </Container>
        </>
    );
}

export default MasterPage;