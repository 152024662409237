import React from 'react';
import { MasterPage } from '../layout';
import { Helmet, HelmetData } from 'react-helmet-async';
const helmetData = new HelmetData({});
function AboutScreen(props) {
    return (
        <MasterPage>
            <Helmet helmetData={helmetData}>
                <title>About</title>
            </Helmet>

            AboutScreen
        </MasterPage>
    );
}

export default AboutScreen;