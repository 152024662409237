import { Client, Account } from "appwrite";

const client = new Client();

const account = new Account(client);

client
    .setEndpoint('https://api.thoxaydung.vn/v1')
    .setProject('6458b1d0405d55ae456b')
    ;

export {
    account,
}