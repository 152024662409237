import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import { AboutScreen, HomeScreen } from "../screens";
import LoginFail from "../screens/auth/LoginFail";

const route = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" >
            <Route index element={<HomeScreen />} />
            <Route path="/about" element={<AboutScreen />} />
            <Route path="/fail" element={<LoginFail />} />
        </Route>
    )
);


export default route;