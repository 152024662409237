import React from 'react';
import { MasterPage } from '../../layout';

function LoginFail(props) {
    return (
        <MasterPage>
            Login Fail
        </MasterPage>
    );
}

export default LoginFail;