import React from 'react';
import { MasterPage } from '../layout';
import { Helmet, HelmetData } from 'react-helmet-async';

const helmetData = new HelmetData({});

function HomeScreen(props) {
    return (
        <MasterPage>
            <Helmet helmetData={helmetData}>
                <title>Hello World</title>
            </Helmet>
            jajraej
        </MasterPage>
    );
}

export default HomeScreen;