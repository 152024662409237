import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { account } from '../../config/appwrite';
import authStore from '../../store/authStore';


function HeaderNavBar(props) {

    useEffect(() => {
        account.get().then(() => {
            authStore.setAuth(true)
        }, error => console.log(error));
    }, [])

    const btnLoginFacebook = async () => {
        await account.createOAuth2Session('google', window.location.origin, `${window.location.origin}/fail`)
    }

    const btnLogout = async () => {
        await account.deleteSession('current').then(() => props.navigate('/'));
    }

    return (
        <Navbar expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand as={Link} to='/'>React-Bootstrap</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to='/'>Home</Nav.Link>
                        <Nav.Link as={Link} to='/about'>About</Nav.Link>
                        <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">
                                Another action
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">
                                Separated link
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Nav >
                        {(authStore.auth) ? (
                            <>
                                <Nav.Link onClick={btnLogout}>Logout</Nav.Link>
                            </>
                        ) : (
                            <>
                                <Nav.Link onClick={btnLoginFacebook}>Login</Nav.Link>
                            </>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default observer(HeaderNavBar);