import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  RouterProvider
} from "react-router-dom";
import './index.css';

import 'bootstrap/dist/css/bootstrap.css';
import route from './router/authRoute';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <RouterProvider router={route} />
    </HelmetProvider>
  </React.StrictMode>
);
